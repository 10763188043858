.App {
  text-align: center;
  background: radial-gradient(50% 50% at 50% 50%, #1D3E6F 0%, #0B182B 100%);
  height: 100vh;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  /* background: rgb(2,0,36); */
  /* background: rgba(2,28,71,255); */
  /* background: linear-gradient(204deg, rgba(2,0,36,1) 0%, rgba(0,35,102,1) 20%, rgba(9,9,121,1) 40%, rgba(255,215,0,1) 100%); */

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.jernus-routes {
  height: 200px;
}

