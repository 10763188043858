.jernus-page {
    height: 100vh;
    background-image: url('../../assets/images/jernus-background.png');
}

.jernus-container {
    display: flex;
    flex-direction: row;
}

.jernus {
    // border: white 5px solid;
    width: 25%;
    height: 95vh;
    // margin: auto;
    // margin-top: 2.5vh;
}

.jernus-left {
    display: flex;
    justify-content: start;
}

.jernus-right {
    display: flex;
    justify-content: end;
}

.jernus-main {
    width: 100%;
}

.jernus-img {
    height: 100vh;
    width: 100%;
}